import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { createPopper, Instance } from "@popperjs/core";

@Component({
  selector: "app-pages-dropdown",
  templateUrl: "./pages-dropdown.component.html",
})
export class PagesDropdownComponent implements OnInit {
  isDropdownVisible = false;
  popperInstance: Instance;
  dropdownPopoverShow;
  // dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;
  ngOnInit() {}
  // toggleDropdown(event) {
  //   event.preventDefault();
  //   if (this.dropdownPopoverShow) {
  //     this.dropdownPopoverShow = false;
  //   } else {
  //     this.dropdownPopoverShow = true;
  //     this.createPoppper();
  //   }
  // }
  // createPoppper() {
  //   createPopper(
  //     this.btnDropdownRef.nativeElement,
  //     this.popoverDropdownRef.nativeElement,
  //     {
  //       placement: "bottom-start",
  //     }
  //   );
  // }

  toggleDropdown(event: Event) {
    event.preventDefault();
    this.isDropdownVisible = !this.isDropdownVisible;

    if (this.isDropdownVisible) {
      this.createPopper();
    } else {
      this.destroyPopper();
    }
  }

  createPopper() {
    this.popperInstance = createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }

  destroyPopper() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
  }
}
